import Resource from "./resource"

export default class APIResource extends Resource {

  constructor() {
    super()
  }

  static callAPI(params) {
    return this.prototype.post(params)
  }

}
