import {createStore} from 'vuex'
//import user from './modules/user'

export default createStore({
    state: {
        sessionkey: ''
    },
    mutations: {
        setSessionkey(state, payload) {
            state.sessionkey = payload
        },

    },
    actions: {
        setSessionkey({commit}, payload) {
            commit('setSessionkey', payload)
        }
    },
    getters: {
        getSessionKey(state) {
            return state.sessionkey
        }
    }
})
