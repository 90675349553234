export const doesFieldMeetLengthRequirements = (min, max, value = "") => {
  return value.length >= min && value.length <= max
}

export const inputFieldIsNotEmpty = (value = "") => {
  return value.toString().trim().length > 0
}

export const isValidEmailAddress = (value = "") => {
  const formatted_value = value.toString().trim()
  const regexpEmail = new RegExp('([\\w-+]+(?:\\.[\\w-+]+)*@(?:[\\w-]+\\.)+[a-zA-Z]{2,7})')
  return formatted_value && formatted_value.length > 0 && regexpEmail.test(formatted_value)
}
