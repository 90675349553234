<template>
  <div class="row justify-content-md-center mt-5">
    <div class="col-4">
      <div class="card">
        <div class="card-header bg-success text-white">
          All Done!
        </div>
        <div class="card-body text-start">
          <p class="card-text">Your password has been successfully reset.</p>
          <p class="card-text">Please click the Ok button to be re-directed to the login page where you can login with
            your new password.</p>
        </div>
        <div class="card-footer">
          <div class="col">
            <a href="https://sminfinity.com" class="btn btn-primary">Ok</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {}
</script>

