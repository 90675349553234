
import {defineComponent, computed, ref, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {sha256} from 'js-sha256'
import {doesFieldMeetLengthRequirements, isValidEmailAddress, inputFieldIsNotEmpty} from '@/plugins/validator'
import APIResource from '@/store/api/APIResource'

export default defineComponent({
  setup() {
    const minPasswordLength = 8
    const maxPasswordLength = 30
    let key: string
    const inputEmailField = ref('')
    const inputPasswordField = ref('')
    const inputPasswordVerifyField = ref('')
    const router = useRouter()
    const isErrorReturnedFromApiCall = ref(false)
    let isValidPageSetup = ref(false)
    const alertMessage = ref('For your protection, you are required to change your password at this time.\n' +
        '          Please enter the information below to continue.')

    const getAlertClass = computed(() => {
      return isErrorReturnedFromApiCall.value ? 'alert-danger' : 'alert-primary'
    })

    const isValidResetForm = computed(() => {
      return isValidEmailAddress(inputEmailField.value) &&
          isValidPasswordField(inputPasswordField.value) &&
          isValidPasswordField(inputPasswordVerifyField.value) &&
          inputPasswordField.value === inputPasswordVerifyField.value
    })

    const passwordFieldIsNotBlank = computed(() => {
      return (inputFieldIsNotEmpty(inputPasswordField.value) && inputFieldIsNotEmpty(inputEmailField.value))
    })

    function isValidPasswordField(value = "") {
      const formatted_value = value.toString().trim()
      return doesFieldMeetLengthRequirements(minPasswordLength, maxPasswordLength, formatted_value)
    }

    function processUpdatePassword() {
      if (!key) {
        isErrorReturnedFromApiCall.value = true
        alertMessage.value = "There was an error processing this request. Please try again at a later time."
        return
      }

      isErrorReturnedFromApiCall.value = false
      const params = {
        route: "user::resetpassword",
        password: sha256(inputPasswordField.value),
        email: inputEmailField.value.toString().trim().toLowerCase(),
        onetimecode: key
      }

      APIResource.callAPI(params)
          .then(response => {
            switch (response.data.code) {
              case 200:
                router.replace("/success")
                break
              case 601:
                isErrorReturnedFromApiCall.value = true
                alertMessage.value = "We could not verify the information you have provided. Please check your email address and try again."
                break
              case 609:
                isErrorReturnedFromApiCall.value = true
                alertMessage.value = "Our system indicates you have used this password recently - please enter a new one and submit again."
                break
            }
          })
          .catch(error => {
                isErrorReturnedFromApiCall.value = true
                alertMessage.value = "There was an error processing this request. Please try again at a later time."
                console.error(error)
              }
          )
    }

    const getURLParameters = async () => {
      const route = useRoute()
      if (route && route.query && route.query.bb4a4e93274837ace14d421e8b1d637e2e26c225 &&
          route.query.bb4a4e93274837ace14d421e8b1d637e2e26c225.toString().trim().length > 0) {
        key = route.query.bb4a4e93274837ace14d421e8b1d637e2e26c225.toString().trim()
        isValidPageSetup.value = true
      } else {
        isErrorReturnedFromApiCall.value = true
        alertMessage.value = "This page cannot be accessed at this time. Please try again at a later time."
      }
    }

    onMounted(getURLParameters)

    return {
      alertMessage,
      getAlertClass,
      inputEmailField,
      inputPasswordField,
      inputPasswordVerifyField,
      isValidPageSetup,
      isValidResetForm,
      passwordFieldIsNotBlank,
      processUpdatePassword
    }
  }
})
